import {
  AnchorButton,
  AnchorButtonProps,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  Classes,
} from "@blueprintjs/core";
import classNames from "classnames";
import { darken, rgba } from "polished";
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react";
import styled, { css } from "styled-components";

import { HexClasses } from "./classes.js";
import { srOnlyStyles } from "./SROnly.js";

export const BUTTON_OVERRIDE = `.${Classes.BUTTON}:not(.${Classes.SMALL}):not(.${Classes.LARGE})`;
export const BUTTON_OVERRIDE_SMALL = `.${Classes.BUTTON}.${Classes.SMALL}:not(.${Classes.LARGE})`;

export type HexButtonProps = {
  extraSmall?: boolean;
  subtle?: boolean;
};

type HexButtonInternalProps = {
  $extraSmall?: boolean;
  $subtle?: boolean;
};

type ButtonStylesType = HexButtonInternalProps &
  Pick<ButtonProps, "intent"> &
  Pick<ButtonProps, "minimal">;

export const buttonStyles = css<ButtonStylesType>`
  &&& {
    &.${Classes.BUTTON} {
      /* STYLES FOR ANY PERMUTATION */
      line-height: 1;

      background-image: none;
      border-radius: ${({ theme }) => theme.borderRadius};
      cursor: pointer;

      transition:
        color ${({ theme }) => theme.animation.duration}
          ${({ theme }) => theme.animation.easing},
        background-color ${({ theme }) => theme.animation.duration}
          ${({ theme }) => theme.animation.easing},
        box-shadow ${({ theme }) => theme.animation.duration}
          ${({ theme }) => theme.animation.easing};

      &.${Classes.DISABLED} {
        ${({ theme }) => theme.disabledState};
      }

      /* Override BP margins for multiple children, use 'gap' instead below */
      > *:not(:only-child) {
        margin-right: 0;
      }

      ${({ $extraSmall }) =>
        !$extraSmall &&
        css`
          /* ****** */
          /* SIZE: DEFAULT */
          /* ****** */
          &:not(.${Classes.SMALL}):not(.${Classes.LARGE}) {
            min-width: 28px;
            min-height: 28px;
            padding: 5px 9px;
            gap: 5px;

            /* Icon offsets for icons with text */
            > .${Classes.ICON} {
              &:not(:only-child):first-child {
                margin-left: -2px;
              }
              &:not(:only-child):last-child {
                margin-right: -2px;
              }
            }

            > .${Classes.BUTTON_TEXT} {
              line-height: 16px;
            }
          }

          /* ****** */
          /* SIZE: SMALL */
          /* ****** */
          &.${Classes.SMALL}:not(.${Classes.LARGE}) {
            min-width: 24px;
            min-height: 24px;
            padding: 4px 8px;
            gap: 3px;

            font-size: ${({ theme }) => theme.fontSize.SMALL};

            /* Icon offsets for icons with text */
            > .${Classes.ICON} {
              &:not(:only-child):first-child {
                margin-left: -3px;
              }
              &:not(:only-child):last-child {
                margin-right: -3px;
              }
            }

            > .${Classes.BUTTON_TEXT} {
              line-height: 16px;
            }
          }

          /* ****** */
          /* SIZE: LARGE */
          /* ****** */
          &.${Classes.LARGE}:not(.${Classes.SMALL}) {
            min-width: 36px;
            min-height: 36px;
            padding: 4px 12px;
            gap: 5px;

            font-size: ${({ theme }) => theme.fontSize.LARGE};

            /* Icon offsets for icons with text */
            > .${Classes.ICON} {
              &:not(:only-child):first-child {
                margin-left: -3px;
              }
              &:not(:only-child):last-child {
                margin-right: -3px;
              }
            }

            > .${Classes.BUTTON_TEXT} {
              line-height: 20px;
            }
          }
        `};

      /* ****** */
      /* SIZE: EXTRA SMALL */
      /* ****** */
      ${({ $extraSmall, theme }) =>
        $extraSmall &&
        css`
          &:not(.${Classes.SMALL}):not(.${Classes.LARGE}) {
            min-width: 20px;
            min-height: 20px;
            padding: 0 6px;
            gap: 2px;

            font-size: ${theme.fontSize.SMALL};

            /* Icon offsets for icons with text */
            > .${Classes.ICON} {
              &:not(:only-child):first-child {
                margin-left: -3px;
              }
              &:not(:only-child):last-child {
                margin-right: -3px;
              }
            }

            > .${Classes.BUTTON_TEXT} {
              line-height: 16px;
            }
          }
        `};

      /* BUTTONS WITH INTENTS */
      ${({ intent, theme }) => {
        if (!intent) {
          return;
        }
        const buttonIntent = theme.button.default[intent];
        return css`
          color: ${buttonIntent.fontColor};

          background-color: ${rgba(buttonIntent.backgroundColor, 0.07)};
          box-shadow: inset 0 0 0 1px ${buttonIntent.borderColor};

          &:not(.${Classes.DISABLED}) {
            &:hover {
              background-color: ${rgba(buttonIntent.backgroundColor, 0.1)};
            }

            &:active,
            &.${Classes.ACTIVE} {
              background-color: ${rgba(buttonIntent.backgroundColor, 0.2)};
              color: ${darken(0.075, buttonIntent.fontColor)};
            }
          }

          > .${Classes.ICON}, > .${Classes.ICON} > svg {
            color: ${buttonIntent.fontColor};
          }
        `;
      }};

      /* DEFAULT OR NO INTENT */
      ${({ intent, theme }) =>
        (!intent || intent === "none") &&
        css`
          color: ${theme.button.default.none.fontColor};

          background-color: ${theme.button.default.none.backgroundColor};
          box-shadow: inset 0 0 0 1px ${theme.button.default.none.borderColor};

          &:not(.${Classes.DISABLED}) {
            &:hover {
              background-color: ${theme.hoverColor};
            }

            &:active {
              background-color: ${theme.activeColor};
            }

            &.${Classes.ACTIVE} {
              background-color: ${darken(0.03, theme.activeColor)};
              box-shadow: inset 0 0 0 1px ${darken(0.2, theme.activeColor)};
            }
          }

          > .${Classes.ICON}, > .${Classes.ICON} > svg {
            color: ${theme.iconColor};
          }
        `};

      /* SUBTLE */
      ${({ $subtle }) =>
        $subtle &&
        css`
          box-shadow: none;
        `};

      /* SUBTLE WITH: DEFAULT OR NO INTENT */
      ${({ $subtle, intent, minimal, theme }) =>
        ($subtle || minimal) &&
        (!intent || intent === "none") &&
        css`
          background: ${rgba(
            theme.button.subtleOrMinimal.none.backgroundColor,
            0.1,
          )};

          &:not(.${Classes.DISABLED}) {
            &:hover {
              background-color: ${rgba(
                theme.button.subtleOrMinimal.none.backgroundColor,
                0.15,
              )};
            }

            &:active {
              background-color: ${rgba(
                theme.button.subtleOrMinimal.none.backgroundColor,
                0.2,
              )};
            }

            &.${Classes.ACTIVE} {
              background-color: ${darken(0.01, theme.activeColor)};
              box-shadow: none;
            }
          }
        `};

      /* MINIMAL STYLE */
      ${({ minimal }) =>
        minimal &&
        css`
          background: none;
          box-shadow: none;
        `};

      /* MINIMAL WITH: DEFAULT OR NO INTENT */
      ${({ $extraSmall, intent, minimal, theme }) =>
        minimal &&
        (!intent || intent === "none") &&
        css`
          &.${Classes.SMALL} {
            color: ${theme.fontColor.MUTED};
          }

          ${$extraSmall &&
          css`
            color: ${theme.fontColor.MUTED};
          `}
        `};
    }

    &.${Classes.DIALOG_CLOSE_BUTTON} {
      box-shadow: none;
    }
  }
`;

/**
 * https://blueprintjs.com/docs/#core/components/button
 */
export const InternalHexButton = styled(Button)<
  HexButtonInternalProps & ButtonProps
>`
  && {
    ${buttonStyles};
  }
`;

export const HexButton = React.memo(
  React.forwardRef<
    HTMLButtonElement,
    HexButtonProps & ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
  >(function HexButton({ className, extraSmall, subtle, ...props }, ref) {
    return (
      <InternalHexButton
        ref={ref}
        $extraSmall={extraSmall}
        $subtle={subtle}
        className={classNames(HexClasses.HEX_BUTTON, className)}
        {...props}
      />
    );
  }),
);

export const InternalHexAnchorButton = styled(AnchorButton)<
  HexButtonInternalProps & AnchorButtonProps
>`
  && {
    ${buttonStyles};
  }
`;

export const HexAnchorButton = React.memo(
  React.forwardRef<
    HTMLAnchorElement,
    HexButtonProps & AnchorButtonProps & AnchorHTMLAttributes<HTMLAnchorElement>
  >(function HexAnchorButton({ className, extraSmall, subtle, ...props }, ref) {
    return (
      <InternalHexAnchorButton
        ref={ref}
        $extraSmall={extraSmall}
        $subtle={subtle}
        className={classNames(HexClasses.HEX_ANCHOR_BUTTON, className)}
        {...props}
      />
    );
  }),
);

export const HexButtonGroup = styled(ButtonGroup)<
  Pick<ButtonGroupProps, "minimal">
>`
  &&&&&& {
    /* BUTTON AT TOP LEVEL */
    .${Classes.BUTTON} {
      &:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      &:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }

    /* BUTTON AT SECOND LEVEL */
    .${Classes.POPOVER_TARGET}, span,
    div {
      &:not(:last-child) {
        .${Classes.BUTTON} {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      &:not(:first-child) {
        .${Classes.BUTTON} {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }

    /* Vertical={true} && BUTTON AT SECOND LEVEL */
    &.${Classes.VERTICAL} {
      .${Classes.POPOVER_TARGET}, span,
      div {
        &:not(:last-child):not(:first-child) .${Classes.BUTTON} {
          border-radius: 0;
        }

        &:not(:last-child) .${Classes.BUTTON} {
          /* Keeps proper text aligment while visually hiding the border */
          border-bottom-color: transparent;
        }

        &:first-child {
          .${Classes.BUTTON} {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-top-right-radius: ${({ theme }) => theme.borderRadius};
          }
        }

        &:last-child {
          .${Classes.BUTTON} {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
          }
        }
      }
    }
  }

  ${({ minimal, theme }) =>
    minimal &&
    css`
      &&&&&& {
        .${Classes.BUTTON} {
          box-shadow: none;
          background: none;

          &:hover {
            color: ${theme.fontColor.DEFAULT};
            background-color: ${theme.hoverColor};
          }
        }
      }
    `}
`;

/**
 * Styles a button to only display an icon.
 *
 * Can still take a text prop, but hides it in a11y friendly way
 */
export const iconOnlyButtonStyles = css`
  /* Prevents button from growing in flex container, a common polish issue we have with icon only buttons */
  flex: 0;

  > .${Classes.BUTTON_TEXT} {
    ${srOnlyStyles}
  }

  > .${Classes.ICON} {
    /* This important is unfortunatly needed to make sure the icon is centered with 'invisible' text */
    margin: 0 -7px !important;
  }
`;

/**
 * Button that only displays an icon.
 *
 * Can still take a text prop, but hides it in a11y friendly way
 */
export const IconOnlyButton = styled(HexButton)`
  ${iconOnlyButtonStyles}
`;
