import { Classes, Tooltip, TooltipProps } from "@blueprintjs/core";
import classNames from "classnames";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import styled, { ThemeProvider, css, useTheme } from "styled-components";

import { useDarkTheme } from "../theme/themes/darkTheme";

interface HexPopoverTooltipProps extends TooltipProps {
  realClassName?: string;
}

/**
 * This component is an intermediate component that allows us to use
 * styled components with a class prop that isn't `className`.
 *
 * We bascially just save off the consumer `className` to `realClassName`, applying the
 * styles added by styled components to `popoverClassName`.
 */
const HexPopoverTooltip: React.ComponentType<HexPopoverTooltipProps> =
  React.memo(function HexPopoverTooltip({
    className,
    popoverClassName,
    realClassName,
    ...props
  }) {
    return (
      <Tooltip
        {...props}
        className={realClassName}
        // make sure we don't lose the `popoverClassName` added by the consumer
        popoverClassName={classNames(className, popoverClassName)}
      />
    );
  });

export const TooltipInner = styled.div<{
  $alignContent?: TooltipContentAlignment;
  $maxWidthOverride?: number;
  $maxHeightOverride?: string;
  $scrollContent?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  max-width: ${({ $maxWidthOverride }) =>
    $maxWidthOverride ? $maxWidthOverride : 230}px;

  padding: 5px 8px;

  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  line-height: 16px;

  background: ${({ theme }) => theme.backgroundColor.DEFAULT};
  border-radius: ${({ theme }) => theme.borderRadius};

  > span {
    display: inline-flex;
    align-items: center;
  }

  a {
    color: ${({ theme }) => theme.fontColor.LINK};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.fontColor.LINK};
    }
  }

  ${({ $maxHeightOverride, $scrollContent }) =>
    $scrollContent &&
    css`
      /*
      A non-hardcoded max here is preferred so we maintain a height that dynamically fits
      content when screen size suffices */
      max-height: ${$maxHeightOverride || "40vh"};
      overflow-y: auto;
    `}

  ${({ $alignContent }) =>
    $alignContent === "center" &&
    css`
      align-items: center;
      text-align: center;
    `}
`;

const Content = styled.div`
  max-width: 100%;
  overflow-wrap: break-word;
`;

const SubContent = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
`;

interface ManualTooltipPosition {
  x: number;
  y: number;
}

const FOLLOWING_CURSOR_OFFSET = 10;

export const hexPopoverTooltipStyles = css`
  padding: 3px;

  box-shadow: none;
  transform: scale(1) !important;
  background: none;

  > .${Classes.POPOVER_CONTENT} {
    padding: 0;

    background: none;
    box-shadow: ${({ theme }) => theme.boxShadow.POPOVER};
  }
`;

const StyledHexPopoverTooltip = styled(HexPopoverTooltip)<{
  $manualPosition?: ManualTooltipPosition;
}>`
  && {
    ${hexPopoverTooltipStyles}

    ${({ $manualPosition }) =>
      $manualPosition &&
      css`
        position: fixed;
        top: ${$manualPosition.y}px;
        left: ${$manualPosition.x}px;
      `}
  }
`;

export const HexTooltipUnderlineText = styled.span`
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.fontColor.DEFAULT} 80%,
    transparent 80%
  );
  background-repeat: repeat-x;
  background-position: 0 1.15em;
  background-size: 4px 1px;
`;

type TooltipContentAlignment = "left" | "center";

interface HexTooltipAdditionalProps {
  followCursor?: boolean; // note: cursor-follow behavior does NOT WORK when providing a renderTarget prop
  maxWidthOverride?: number;
  maxHeightOverride?: string;
  scrollContent?: boolean;
  subContent?: ReactNode;
  alignContent?: TooltipContentAlignment;
  useAppTheme?: boolean;
}

export type HexToolTipProps = TooltipProps & HexTooltipAdditionalProps;

/**
 * https://blueprintjs.com/docs/#core/components/tooltip
 */
export const HexTooltip: React.ComponentType<HexToolTipProps> = React.memo(
  function HexTooltip({
    alignContent = "left",
    children,
    className,
    content,
    followCursor = false,
    maxHeightOverride,
    maxWidthOverride,
    scrollContent = false,
    subContent,
    targetTagName = "span",
    useAppTheme = false,
    ...props
  }) {
    const { isCustomTheme } = useTheme();
    const darkTheme = useDarkTheme();

    const [mouseCoords, setMouseCoords] = useState<ManualTooltipPosition>({
      x: 0,
      y: 0,
    });

    const handleMouseMove = useCallback(
      (event) => {
        if (followCursor) {
          setMouseCoords({
            x: event.clientX + FOLLOWING_CURSOR_OFFSET,
            y: event.clientY + FOLLOWING_CURSOR_OFFSET,
          });
        }
      },
      [followCursor],
    );

    const toolTipContent = useMemo(() => {
      return (
        <TooltipInner
          $alignContent={alignContent}
          $maxHeightOverride={maxHeightOverride}
          $maxWidthOverride={maxWidthOverride}
          $scrollContent={scrollContent}
        >
          <Content>{content}</Content>
          {subContent && <SubContent>{subContent}</SubContent>}
        </TooltipInner>
      );
    }, [
      alignContent,
      content,
      maxHeightOverride,
      maxWidthOverride,
      scrollContent,
      subContent,
    ]);

    return (
      <StyledHexPopoverTooltip
        {...props}
        $manualPosition={followCursor ? mouseCoords : undefined}
        content={
          useAppTheme ? (
            toolTipContent
          ) : isCustomTheme ? (
            toolTipContent
          ) : (
            <ThemeProvider theme={darkTheme}>{toolTipContent}</ThemeProvider>
          )
        }
        minimal={true}
        realClassName={className}
        // eslint-disable-next-line react/jsx-no-bind
        renderTarget={({ isOpen, ref, ...tooltipProps }) => {
          if (props.renderTarget != null) {
            return props.renderTarget({
              isOpen,
              ref,
              ...tooltipProps,
            });
          } else {
            return React.createElement(
              targetTagName as string,
              {
                className: Classes.POPOVER_TARGET,
                ref,
                onMouseMove: handleMouseMove,
                ...tooltipProps,
              },
              children,
            );
          }
        }}
      />
    );
  },
);

/* Like HexTooltip, except styles are passed through to the `popoverClassName` prop. */
export const HexTooltipPopoverStylable: React.ComponentType<TooltipProps> = ({
  className,
  ...rest
}) => <HexTooltip {...rest} popoverClassName={className} />;
