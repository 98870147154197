import {
  DataConnectionHexVersionLinkId,
  DataConnectionLinkType,
  notEmpty,
  stableEmptyObject,
} from "@hex/common";
import { useCallback } from "react";
import { createSelector } from "reselect";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import {
  DataConnectionHexVersionLinkMP,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import { useProjectContext } from "../../util/projectContext";

export interface UseDataConnectionHexVersionLinksSelectorArgs<T> {
  selector: (
    dataConnectionHexVersionLink: Record<
      DataConnectionHexVersionLinkId,
      DataConnectionHexVersionLinkMP | undefined
    >,
  ) => T;
  safe?: boolean;
  equalityFn?: (left: T, right: T) => boolean;
}

export function useDataConnectionHexVersionLinksSelector<T>({
  equalityFn,
  safe,
  selector,
}: UseDataConnectionHexVersionLinksSelectorArgs<T>): T {
  const { hexVersionId } = useProjectContext({ safe }) ?? {
    hexVersionId: undefined,
  };

  return useSelector((state) => {
    if (hexVersionId == null) {
      if (safe) {
        return selector({});
      } else {
        throw new Error(`Missing hexVersionId`);
      }
    }
    const dataConnectionHexVersionLinksState = hexVersionMPSelectors
      .getDataConnectionHexVersionLinkSelectors(hexVersionId)
      .selectEntities(state);

    if (dataConnectionHexVersionLinksState == null) {
      throw new Error(
        `Missing dataConnectionHexVersionLink state for hex version: ${hexVersionId}`,
      );
    }

    return selector(dataConnectionHexVersionLinksState);
  }, equalityFn);
}

export interface UseDataConnectionHexVersionLinksGetterArgs<
  A extends unknown[],
  SAFE extends boolean,
  T,
> {
  selector?: (
    dataConnectionHexVersionLinks: Record<
      DataConnectionHexVersionLinkId,
      DataConnectionHexVersionLinkMP | undefined
    >,
    ...args: A
  ) => T;

  /** @default false */
  safe?: SAFE;
}

export type UseDataConnectionHexVersionLinksGetterResult<
  A extends unknown[],
  T,
> = (...args: A) => T;

export function useDataConnectionHexVersionLinksGetter<
  A extends unknown[],
  SAFE extends boolean = false,
  T = Record<
    DataConnectionHexVersionLinkId,
    DataConnectionHexVersionLinkMP | undefined
  >,
>(
  args: UseDataConnectionHexVersionLinksGetterArgs<A, SAFE, T> = {},
): UseDataConnectionHexVersionLinksGetterResult<A, T> {
  const { hexVersionId } = useProjectContext({ safe: args.safe ?? false }) ?? {
    hexVersionId: undefined,
  };
  const store = useStore();
  const selectorRef = useStableRef(args.selector);

  return useCallback(
    (...cbArgs: A) => {
      if (hexVersionId == null) {
        if (args.safe) {
          return selectorRef.current
            ? selectorRef.current(stableEmptyObject(), ...cbArgs)
            : (stableEmptyObject() as T);
        }

        throw new Error(
          `Tried to call useDataConnectionHexVersionLinksGetter without a project context`,
        );
      }

      const dataConnectionHexVersionLinks = hexVersionMPSelectors
        .getDataConnectionHexVersionLinkSelectors(hexVersionId)
        .selectEntities(store.getState());

      if (dataConnectionHexVersionLinks == null) {
        throw new Error(
          `Missing dataConnectionHexVersionLinks state for hex version: ${hexVersionId}`,
        );
      }

      // this cannot be conditionally chained/nullish coalesced since
      // the provided selector may intentionally return undefined
      return selectorRef.current
        ? selectorRef.current(dataConnectionHexVersionLinks, ...cbArgs)
        : (dataConnectionHexVersionLinks as unknown as T);
    },
    [hexVersionId, store, selectorRef, args.safe],
  );
}

export const importedOrgDataConnectionIdsSelector = createSelector(
  (
    links: Record<
      DataConnectionHexVersionLinkId,
      DataConnectionHexVersionLinkMP | undefined
    >,
  ) => links,
  (links) =>
    Object.values(links)
      .filter(
        (link) =>
          link?.dataConnectionLinkType === DataConnectionLinkType.WORKSPACE,
      )
      .map((link) => link?.dataConnectionId)
      .filter(notEmpty),
);

export const importedDataConnectionIdsSetSelector = createSelector(
  importedOrgDataConnectionIdsSelector,
  (ids) => new Set(ids),
);
