import { Classes, SpinnerProps, SpinnerSize } from "@blueprintjs/core";
import { type EnumValues } from "@hex/common";
import classNames from "classnames";
import { rgba } from "polished";
import React, { type ReactNode, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

import { CyData } from "../util/cypress";

import { HexDotGridPulse } from "./HexDotGridPulse";

function createStrokeDasharray({ segments }: { segments: number }): string {
  const segmentLength = 1 / segments;
  return `${segmentLength * 0.25} ${segmentLength * 0.75}`;
}

const Track = styled.circle`
  fill: none;
  stroke: currentColor;
  opacity: 0.6;
  transform-origin: center center;
  will-change: transform;
  stroke-width: 1;
  animation: 4s rotate linear infinite;

  @keyframes rotate {
    to {
      transform: rotate(-1turn);
    }
  }
`;

const Loader = styled.circle`
  fill: none;
  stroke: currentColor;
  stroke-width: 1;
  transform-origin: center center;
  will-change: transform;
  animation: 0.7s load linear infinite;

  @media (prefers-reduced-motion) {
    animation-duration: 1.6s !important;
    animation-iteration-count: infinite !important;
  }

  @keyframes load {
    from {
      transform: rotate(-1turn);
    }
  }
`;

export const HexSpinnerSizes = {
  STANDARD: SpinnerSize.STANDARD,
  LARGE: SpinnerSize.LARGE,
} as const;

const Spinner: React.ComponentType<{
  size?: EnumValues<typeof HexSpinnerSizes>;
}> = ({ size }) => {
  const isLarge = (size ?? 0) >= HexSpinnerSizes.LARGE;
  const diameter = isLarge ? 32 : 14;

  return (
    <svg
      fill="none"
      height={diameter}
      viewBox={`0 0 ${diameter} ${diameter}`}
      width={diameter}
      xmlns="http://www.w3.org/2000/svg"
    >
      <Track
        cx={diameter * 0.5}
        cy={diameter * 0.5}
        pathLength="1"
        r={diameter * 0.5 - 1}
        strokeLinecap="round"
        style={{
          strokeDasharray: createStrokeDasharray({
            segments: isLarge ? 14 : 9,
          }),
        }}
      />
      <Loader
        cx={diameter * 0.5}
        cy={diameter * 0.5}
        pathLength="1"
        r={diameter * 0.5 - 1}
        strokeDasharray=".3 .7"
        strokeDashoffset="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export enum HexSpinnerDescriptionPosition {
  BELOW = "below",
  RIGHT = "right",
}

const SpinnerInner = styled.div<{ $subtleSpinner?: boolean }>`
  --spring-duration: 0.667s;
  --spring-easing: linear(
    0,
    0.01,
    0.039 1.8%,
    0.157 3.9%,
    0.796 11%,
    1.022 14%,
    1.103,
    1.164,
    1.207,
    1.231 20%,
    1.238,
    1.238,
    1.231,
    1.219 24.2%,
    1.18 26.5%,
    1.057 32.1%,
    1.005 34.9%,
    0.965 38%,
    0.953,
    0.946 41.2%,
    0.943 43.3%,
    0.948 45.7%,
    0.998 56.2%,
    1.007,
    1.013 62.2% 67.1%,
    1.001 77.3%,
    0.997 82.7%,
    1
  );

  color: ${({ $subtleSpinner, theme }) =>
    $subtleSpinner ? rgba(theme.iconColor, 0.75) : theme.highlightColor};
  transform-origin: center center;
  animation: var(--spring-duration) scale var(--spring-easing);
  animation-fill-mode: both;

  @keyframes scale {
    from {
      transform: scale(0.6) translateZ(0);
    }
  }

  &.anim-exit-active {
    animation: var(--exit-duration) exit-scale cubic-bezier(1, -0.4, 0.35, 0.95);
  }
  &.anim-exit-done {
    transform: scale(0) translateZ(0);
  }

  @keyframes exit-scale {
    to {
      transform: scale(0) translateZ(0);
    }
  }
`;

const Description = styled.div<{ $affectParentSize: boolean }>`
  display: flex;
  margin-left: 7px;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};

  line-height: 18px;

  text-align: center;

  ${({ $affectParentSize }) =>
    $affectParentSize &&
    `
    position: absolute;
    bottom: 0;
    width: max-content;
    transform: translateY(calc(100% + 10px));
  `}
`;

const SpinnerWrapper = styled.div<{
  $size?: number;
  $descriptionPosition?: HexSpinnerDescriptionPosition;
}>`
  position: relative;
  display: flex;
  flex: 1 1 auto;

  ${({ $descriptionPosition }) =>
    $descriptionPosition === HexSpinnerDescriptionPosition.BELOW &&
    `
    flex-direction: column;
    gap: 10px;

    ${Description} {
      margin-left: 0;
    }
  `}

  align-items: center;
`;

export type HexSpinnerProps = SpinnerProps & {
  description?: ReactNode;
  descriptionPosition?: HexSpinnerDescriptionPosition;
  subtleSpinner?: boolean;
  cyData?: string;
  withDotGridPulse?: boolean;
  isMounted?: boolean;
};

/**
 * Exit animation usage:
 *
 * ```ts
  // without exit animations
  {isMounted && <HexSpinner />}

  // with exit animations
  <HexSpinner isMounted={isMounted} />
 * ```
 * @param {boolean} props.isMounted - Opt into exit animations by mounting and dismounting component with this prop
 */
export const HexSpinner: React.ComponentType<HexSpinnerProps> = React.memo(
  function HexSpinner({
    className,
    cyData,
    description,
    descriptionPosition = HexSpinnerDescriptionPosition.RIGHT,
    isMounted = true,
    size,
    subtleSpinner = false,
    withDotGridPulse,
    ...props
  }) {
    const nodeRef = useRef<HTMLDivElement>(null);
    const exitDuration = 300;

    return (
      <>
        {withDotGridPulse && <HexDotGridPulse isMounted={isMounted} />}
        <CSSTransition
          classNames="anim"
          in={isMounted}
          mountOnEnter={true}
          nodeRef={nodeRef}
          timeout={exitDuration}
          unmountOnExit={true}
        >
          <SpinnerWrapper
            {...props}
            $descriptionPosition={descriptionPosition}
            $size={size}
            className={classNames(Classes.SPINNER, className)}
            data-cy={cyData ?? CyData.HEX_SPINNER}
          >
            <SpinnerInner
              ref={nodeRef}
              $subtleSpinner={subtleSpinner}
              style={
                {
                  "--exit-duration": exitDuration + "ms",
                } as React.CSSProperties
              }
            >
              <Spinner size={size} />
            </SpinnerInner>
            {description && (
              <Description
                $affectParentSize={
                  Boolean(withDotGridPulse) &&
                  descriptionPosition === HexSpinnerDescriptionPosition.BELOW
                }
              >
                {description}
              </Description>
            )}
          </SpinnerWrapper>
        </CSSTransition>
      </>
    );
  },
);
