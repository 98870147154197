import { Classes, Collapse } from "@blueprintjs/core";
import React, { useCallback, useMemo } from "react";
import styled, { useTheme } from "styled-components";

import {
  MaximizeIcon,
  MinimizeIcon,
  SingleChevronDownIcon,
  SingleChevronRightIcon,
} from "../components/icons/CustomIcons";
import { useToggleState } from "../hooks/useToggleState";

import { HexClasses } from "./classes.js";

export interface HexCollapseProps {
  title: string | React.ReactNode;
  icon?: React.ReactNode;
  rightElement?: React.ReactNode;
  togglePosition?: "left" | "right";
  duration?: "default" | "medium" | "large";
  /**
   * Used to override the toggle function and control state externally
   */
  isOpen?: boolean;
  isOpenDefault?: boolean;
  toggleIsOpen?: () => void;
  /**
   * If true, the toggle will be disabled and the toggle icon will be hidden.
   */
  disabled?: boolean;
  keepChildrenMounted?: boolean;
  /**
   * If provided, this will be called instead of toggling the toggle
   * when the header is clicked outside of the icon.
   */
  onClick?: () => void;
  className?: string;
  toggleIconType?: "chevron" | "min-max-arrows";
  "data-cy"?: string;
}

const Wrapper = styled.div<{ $duration: number }>`
  .${Classes.COLLAPSE} {
    transition: height ${({ $duration }) => `${$duration}ms`}
      ${({ theme }) => theme.animation.easing};

    .${Classes.COLLAPSE_BODY} {
      transform: none !important;
    }
  }
`;

export const HexCollapse: React.ComponentType<HexCollapseProps> = React.memo(
  function HexCollapse({
    children,
    className,
    "data-cy": dataCy,
    disabled = false,
    duration = "default",
    icon,
    isOpen: isOpenOverride,
    isOpenDefault = true,
    keepChildrenMounted = false,
    onClick,
    rightElement,
    title,
    toggleIconType = "chevron",
    toggleIsOpen: toggleOverride,
    togglePosition = "left",
  }) {
    const theme = useTheme();
    const [isOpen, _, { toggle: toggleIsOpen }] = useToggleState(isOpenDefault);

    const resolvedIsOpen = isOpenOverride ?? isOpen;
    const resolvedToggle = toggleOverride ?? toggleIsOpen;

    const animationDuration = useMemo(() => {
      if (duration === "medium") {
        return theme.animation.durationMediumMs;
      } else if (duration === "large") {
        return theme.animation.durationLargeMs;
      }
      return theme.animation.durationMs;
    }, [theme.animation, duration]);

    const toggleHandler = useCallback(
      (evt) => {
        evt.stopPropagation();
        resolvedToggle();
      },
      [resolvedToggle],
    );

    const toggleIcon = useMemo(() => {
      const OpenIcon =
        toggleIconType === "chevron" ? SingleChevronRightIcon : MaximizeIcon;
      const CloseIcon =
        toggleIconType === "chevron" ? SingleChevronDownIcon : MinimizeIcon;

      return disabled ? null : resolvedIsOpen ? (
        <CloseIcon onClick={toggleHandler} />
      ) : (
        <OpenIcon onClick={toggleHandler} />
      );
    }, [disabled, resolvedIsOpen, toggleHandler, toggleIconType]);

    return (
      <Wrapper $duration={animationDuration} className={className}>
        <ToggleHeader
          className={HexClasses.HEX_COLLAPSE_TOGGLE_HEADER}
          data-cy={dataCy}
          onClick={onClick ? onClick : resolvedToggle}
        >
          <LeftWrapper>
            {togglePosition === "left" && toggleIcon}
            <HexCollapseHeader>
              {icon}
              {title}
            </HexCollapseHeader>
          </LeftWrapper>
          {rightElement ? <div>{rightElement}</div> : null}
          {togglePosition === "right" && toggleIcon}
        </ToggleHeader>
        <Collapse
          isOpen={resolvedIsOpen}
          keepChildrenMounted={keepChildrenMounted}
          transitionDuration={animationDuration}
        >
          {children}
        </Collapse>
      </Wrapper>
    );
  },
);

export const ToggleHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  padding: 15px 10px 5px 0;

  svg {
    color: ${({ theme }) => theme.iconColor};
  }

  &:hover {
    cursor: pointer;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
`;

export const HexCollapseHeader = styled.div`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.fontColor.DEFAULT};
  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  font-size: ${({ theme }) => theme.fontSize.DEFAULT};
  user-select: none;

  .${Classes.ICON} {
    margin-right: 6px;
  }
`;
