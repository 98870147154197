import React, { type ComponentProps, type ReactElement, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

const DotGrid = styled.div`
  @property --grow {
    syntax: "<percentage>";
    initial-value: 0%;
    inherits: false;
  }

  --dot-grid-color: ${({ theme }) => theme.highlightColor};

  position: absolute;
  inset: 0;

  mask-size: var(--pulse-diameter) var(--pulse-diameter);
  mask-position: center;
  mask-image: radial-gradient(
    rgba(0, 0, 0, 0.05) 25px,
    rgba(0, 0, 0, 0.25) 50px,
    rgba(0, 0, 0, 0.45) 100px,
    transparent 65%
  );

  &::after {
    content: "";
    position: absolute;
    inset: 0;

    background: radial-gradient(
      circle at center,
      var(--dot-grid-color) 1px,
      transparent 1px
    );
    background-size: 8px 8px;

    mask-image: radial-gradient(
        transparent calc(var(--grow) - 11%),
        rgba(0, 0, 0, 0.04) calc(var(--grow) - 11%),
        black calc(var(--grow) + 0%),
        transparent calc(var(--grow) + 3%)
      ),
      radial-gradient(
        transparent calc(var(--grow) + 0%),
        rgba(0, 0, 0, 0.04) calc(var(--grow) + 9%),
        black calc(var(--grow) + 20%),
        transparent calc(var(--grow) + 23%)
      ),
      radial-gradient(
        transparent calc(var(--grow) + 20%),
        rgba(0, 0, 0, 0.04) calc(var(--grow) + 29%),
        black calc(var(--grow) + 40%),
        transparent calc(var(--grow) + 43%)
      ),
      radial-gradient(
        transparent calc(var(--grow) + 40%),
        rgba(0, 0, 0, 0.04) calc(var(--grow) + 49%),
        black calc(var(--grow) + 60%),
        transparent calc(var(--grow) + 63%)
      ),
      radial-gradient(
        transparent calc(var(--grow) + 60%),
        rgba(0, 0, 0, 0.04) calc(var(--grow) + 69%),
        black calc(var(--grow) + 80%),
        transparent calc(var(--grow) + 83%)
      );

    mask-size: var(--pulse-diameter) var(--pulse-diameter);
    mask-position: center;

    animation: var(--pulse-speed, 1.1s) grow infinite linear;

    @keyframes grow {
      to {
        --grow: 20%;
      }
    }
  }
`;

const VerticalLinearGradientMask = styled.div`
  position: absolute;
  inset: 0;
  mask-image: linear-gradient(
    transparent,
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 1) 90%,
    transparent
  );
`;

const RevealAnimation = styled.div`
  position: absolute;
  inset: 0;
  mask-position: center;
  clip-path: circle(var(--pulse-diameter));
  animation: calc(6 * var(--pulse-speed, 1.1s)) reveal linear;
  animation-fill-mode: both;

  @keyframes reveal {
    from {
      clip-path: circle(0%);
    }
  }
`;

const ExitAnimation = styled.div`
  @property --radius {
    syntax: "<percentage>";
    initial-value: 0%;
    inherits: false;
  }

  position: absolute;
  pointer-events: none;
  inset: 0;
  mask-position: center;
  mask-size: var(--pulse-diameter) var(--pulse-diameter);
  mask-image: radial-gradient(
    transparent calc(var(--radius) - 100%),
    rgba(0, 0, 0, 1) var(--radius),
    rgba(0, 0, 0, 1)
  );

  &.anim-exit-active {
    animation:
      var(--exit-duration) exit-wave ease-out,
      var(--exit-duration) exit-opacity ease-in-out;
    animation-fill-mode: both;
  }
  &.anim-exit-done {
    --radius: 100%;
    opacity: 0;
  }

  @keyframes exit-wave {
    to {
      --radius: 100%;
    }
  }

  @keyframes exit-opacity {
    to {
      opacity: 0;
    }
  }
`;

/**
 * Exit animation usage:
 *
 * ```ts
  // without exit animations
  {isMounted && <HexDotGridPulse />}

  // with exit animations
  <HexDotGridPulse isMounted={isMounted} />
 * ```
 * @param {boolean} props.isMounted - Opt into exit animations by mounting and dismounting component with this prop
 */
export function HexDotGridPulse({
  className,
  isMounted = true,
  style,
}: {
  isMounted?: boolean;
} & Pick<ComponentProps<"div">, "className" | "style">): ReactElement | null {
  const nodeRef = useRef<HTMLDivElement>(null);
  const exitDuration = 500;

  return (
    <CSSTransition
      classNames="anim"
      in={isMounted}
      mountOnEnter={true}
      nodeRef={nodeRef}
      timeout={exitDuration}
      unmountOnExit={true}
    >
      <ExitAnimation
        ref={nodeRef}
        className={className}
        style={
          {
            "--exit-duration": exitDuration + "ms",
            "--pulse-diameter": "max(120vw, 120vh)",
            "--pulse-speed": "1.5s",
            ...style,
          } as React.CSSProperties
        }
      >
        <VerticalLinearGradientMask>
          <RevealAnimation>
            <DotGrid />
          </RevealAnimation>
        </VerticalLinearGradientMask>
      </ExitAnimation>
    </CSSTransition>
  );
}
